import React, { useState, useRef, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import { Input, Pagination } from 'antd';

// components
import Layout from '../components/layout';
import Seo from '../components/seo';

// styled-comonents
const Container = styled.section`
  max-width: 90%;
  width: 1028px;
  margin: 0 auto;
  padding: 2em 0;
`;

const PaginationContainer = styled.section`
  display: flex;
  justify-content: center;
  padding: 2em 0;
`;

const DocumentsContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 0 0 0;
`;

const DocumentItem = styled.div`
  max-width: 600px;
  width: 70%;
  padding: 1em 2em;
  border: 1px solid #c9c9c9;
  border-radius: 10px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
`;

const DocumentName = styled.a`
  font-size: 24px;
  color: #000;
`;

const DocumentUrlContainer = styled.div`
  margin-bottom: 8px;
  display: block;
  float: left;
  clear: left;
`;

const DocumentUrl = styled.a`
  display: block;
  font-size: 10px;
  color: #7a99ac;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const { Search } = Input;

const PAGE_SIZE = 10;

const Documents = React.memo(() => {
  const [query, setQuery] = useState('');
  const [finalQuery, setFinalQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState([]);
  const [filterDocuments, setFilterDocuments] = useState([]);

  const { allDocumentsJson: { nodes: documents = [] } = {} } = useStaticQuery(graphql`
    query {
      allDocumentsJson {
        nodes {
          id
          keywords
          name
          reference
          url
        }
      }
    }
  `);

  useEffect(() => {
    const timeOutId = setTimeout(() => setFinalQuery(query.trim()), 850);
    return () => clearTimeout(timeOutId);
  }, [query]);

  useEffect(() => {
    const queryRegex = new RegExp(`${finalQuery}`, 'gi');
    setFilterDocuments(
      documents.filter(
        ({ name, reference, keywords }) =>
          queryRegex.test(name) || queryRegex.test(reference) || queryRegex.test(keywords)
      )
    );
    setCurrentPage(1);
  }, [finalQuery, documents]);

  useEffect(() => {
    setDataPerPage(
      filterDocuments.slice(currentPage * PAGE_SIZE - PAGE_SIZE, PAGE_SIZE * currentPage)
    );
  }, [documents, currentPage, filterDocuments]);

  const onChange = (event) => setQuery(event.target.value);

  const onSearch = (value) => setQuery(value.trim());

  const onChangePage = (page) => setCurrentPage(page);

  const totalDataRef = useRef(filterDocuments.length);
  totalDataRef.current = filterDocuments.length;

  return (
    <Layout>
      <Seo title="Centro de Documentos - Sto de Colombia" />
      <Container>
        <Search
          allowClear
          onChange={onChange}
          onSearch={onSearch}
          placeholder="Buscar un documento..."
        />
        <DocumentsContainer>
          {totalDataRef.current > 0 ? (
            dataPerPage.map((item) => (
              <DocumentItem key={item.id}>
                <DocumentName
                  href={`http://stocolombia.com/${item.url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.name}
                </DocumentName>
                <DocumentUrlContainer>
                  <DocumentUrl
                    href={`http://stocolombia.com/${item.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {`http://stocolombia.com/${item.url}`}
                  </DocumentUrl>
                </DocumentUrlContainer>
                <span>{`Referencia: ${item.reference}`}</span>
              </DocumentItem>
            ))
          ) : (
            <span> No Existen Resultados para esta busqueda</span>
          )}
        </DocumentsContainer>
        {totalDataRef.current > 5 && (
          <PaginationContainer>
            <Pagination
              current={currentPage}
              onChange={onChangePage}
              showSizeChanger={false}
              total={totalDataRef.current}
            />
          </PaginationContainer>
        )}
      </Container>
    </Layout>
  );
});

export default Documents;
